import { FC } from 'react';
import { Upload } from 'antd';
import { Controller } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import useQuoteForm, { Props, ReceivedProps } from './hook';
import { QuoteFormStyles } from './styled';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
const { Dragger } = Upload;

const uploadButton = (
    <div className="text-[#5392F0]">
        <img src={uploadIcon} alt="upload image icon" />
        <div>写真</div>
    </div>
);

const QuoteFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    dragProps,
    dummyRequest,
}) => {
    const typeOptions: SelectSearchOption[] = [
        { value: 'consultation', name: 'ご相談' },
        { value: 'quotation_request', name: 'お見積もり依頼' },
        { value: 'new_enrollment', name: '新規加入' },
        { value: 'plan_change', name: 'プランの変更' },
        { value: 'other', name: 'その他' },
    ];

    const typeOptions2: SelectSearchOption[] = [
        { value: 'all', name: 'すべて' },
        { value: 'medical_insurance', name: '医療保険' },
        { value: 'life_insurance', name: '死亡保険' },
        { value: 'nursing_care_insurance', name: '介護保険' },
        { value: 'inheritance_plan', name: '相続プラン' },
    ];
    const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
        value: item.value,
        name: item.title,
    }));
    return (
        <QuoteFormStyles className="p-[28px]">
            <div className="quote-form-container p-[28px]">
                <form onSubmit={handleSubmit(submitData)}>
                    <div className="w-full flex flex-col gap-y-[14px]">
                        <div className="flex flex-col w-full">
                            <div className="flex items-center mb-3">
                                <div className="mr-4 text-base">
                                    写真アップロード
                                </div>
                                <img
                                    src={warningIcon}
                                    alt="warning circle icon"
                                />
                                <div>1:1 スケール画像</div>
                            </div>
                            <div>
                                <Upload
                                    listType="picture-card"
                                    customRequest={dummyRequest}
                                    locale={{
                                        uploading: 'アップロード中',
                                    }}
                                >
                                    {uploadButton}
                                </Upload>
                            </div>
                        </div>

                        <div className="flex flex-col w-full max-w-[350px]">
                            <Controller
                                name="title"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-base mb-[4px] font-medium text-[#344054]">
                                            タイトル
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox"
                                        ></input>
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col w-full max-w-[350px]">
                            <div className="input-add-user d-flex flex-column">
                                <p style={{ marginTop: 0 }}>ステータス</p>
                            </div>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <SelectSearch
                                        options={statusOptions}
                                        search
                                        filterOptions={handleFilter}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                    />
                                )}
                            />
                        </div>

                        <div className="flex flex-col w-full max-w-[350px]">
                            <div className="input-add-user d-flex flex-column">
                                <p style={{ marginTop: 0 }}>タイプ</p>
                            </div>
                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => (
                                    <SelectSearch
                                        options={typeOptions}
                                        search
                                        filterOptions={handleFilter}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                    />
                                )}
                            />
                        </div>

                        <div className="flex flex-col w-full max-w-[350px]">
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-base mb-[4px] font-medium text-[#344054]">
                                            住所
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox"
                                        ></input>
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col w-full">
                            <Controller
                                name="note"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <p className="text-base mb-[4px] font-medium text-[#344054]">
                                            備考
                                        </p>
                                        <textarea
                                            {...field}
                                            className="rounded-[2px] border-[1px] border-[#CDD1D5] p-[5px]"
                                            rows={4}
                                            maxLength={100}
                                        ></textarea>
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex flex-col w-full max-w-[500px]">
                            <Dragger {...dragProps}>
                                <div className="flex p-3 items-center">
                                    <div className="mr-2">
                                        <img
                                            src={cloudUploadIcon}
                                            alt="cloud upload icon"
                                        />
                                    </div>
                                    <div className="text-left">
                                        <p className="ant-upload-text">
                                            ファイルを選択してください
                                        </p>
                                        <p className="ant-upload-hint">
                                            10MB以下のJPG、PNG、またはPDF、ファイルを対応します。
                                        </p>
                                    </div>
                                    <div className="text-[#215493] border-solid border-[1px] border-[#215493] rounded flex items-center px-3 py-2">
                                        選択
                                    </div>
                                </div>
                            </Dragger>
                        </div>
                    </div>

                    <div
                        className="d-flex flex-row text-lg"
                        style={{
                            marginTop: '14px',
                        }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            style={{ width: '167.5px' }}
                            type="button"
                            onClick={() => { }}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{ marginLeft: '10px', width: '167.5px' }}
                            type="submit"
                        >
                            保存
                        </button>
                    </div>
                </form>
            </div>
        </QuoteFormStyles>
    );
};

const QuoteForm: FC<ReceivedProps> = (props) => {
    return <QuoteFormLayout {...useQuoteForm(props)} />;
};

export default QuoteForm;
