import { TypeOfT } from "utils/type/translations/Translation.type";
import { minLength, onlyNumbers, required, validKatakana, validZipCode } from "../validator";

const customerInfoValidation = (t: TypeOfT) => ({
  first_name: () => ({
    required: required("customer_first_name", t),
  }),
  last_name: () => ({
    required: required("customer_last_name", t),
  }),
  first_name_kana: () => ({
    required: required("customer_first_name_kana", t),
    validate: {
      validKatakana: validKatakana("customer_first_name_kana", t),
    },
  }),
  last_name_kana: () => ({
    required: required("customer_last_name_kana", t),
    validate: {
      validKatakana: validKatakana("customer_last_name_kana", t),
    },
  }),
  phone: () => ({
    required: required("customer_phone", t),
  }),
  zipcode: () => ({
    required: required("customer_zipcode", t),
    // validate: {
    //   regex: validZipCode('zipcode', t),
    // },
  }),
  address: () => ({
    required: required("customer_address", t),
  })
});

export default customerInfoValidation;