import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Upload, message, Modal, DatePicker, Radio, Input, Spin } from 'antd';
// import useSPQuoteForm, { ReceivedProps, Props } from './hook';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import paymentValidation from 'utils/validation/registers/payment';
import { PaymentElement, Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { alertSuccessPopup2 } from 'utils/helper/appHelper';
import invoiceApi from 'services/invoiceApi';
import querystring from 'query-string'

const inputStyle = {
    fontSize: '16px',
    lineHeight: '38px',
    border: '1px solid #000'
}

const FormPayment = ({
    handleSubmit,
    control,
    register,
    errors,
    setValue,
    handleValidateWhitespace,
    isCard,
    setIsCard,
    errorDate,
    clientSecret,
    // submitData
}: any) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const params = useParams();
    const { id }: any = params;
    let storage = localStorage.getItem('line:accessToken');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const queryObj = querystring.parse(location.search)
    const submitData = async (value: any, evt: any) => {
        evt.preventDefault();
        setLoading(true)
        if (isCard) {
            if (!stripe || !elements) {
                return;
            }
            const cardElement = elements.getElement(CardNumberElement);
            if (cardElement) {
                const { paymentIntent, error }: any = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: evt.target.name.value
                        }
                    }
                });
                if (error) {
                    setErrorMessage(error.message);
                } else {
                    // history.push({ pathname: "/line/orders/success" });
                    try {
                        const res = await invoiceApi.updatePaymentIntent(storage, id, paymentIntent.id)
                        if (res) {
                            // console.log('res', res)
                            alertSuccessPopup2({
                                title: '決済完了',
                                confirmButtonText: '閉じる'
                            });
                            setErrorMessage(null)
                            // console.log("paymentIntent", paymentIntent)
                            navigate(`/sp/quote/history${queryObj.top === 'true' ? '?top=true' : ''}`)
                        }
                    } catch (error) {
                        console.log('error', error)
                    }

                }

                // console.log("error", error);
            }

        } else {
            const body = {
                direct_payment: true
            }
            try {
                const res = await invoiceApi.updatePaymentIntentCash(storage, id, body)
                if (res) {
                    alertSuccessPopup2({
                        title: '決済完了',
                        confirmButtonText: '閉じる'
                    });
                    navigate(`/sp/quote/history${queryObj.top === 'true' ? '?top=true' : ''}`);
                    setLoading(false)
                }
            } catch (error) {
                console.log('error', error)
            }

        }
        setLoading(false)
        // setIsLoading(true);

        // navigate(`/sp/quote/history${queryObj.top === 'true' ? '?top=true' : ''}`);
        // handleResetFields();

    };

    return (
        <form onSubmit={handleSubmit(submitData)}>
            <div className="w-full flex flex-col gap-y-[14px] p-[15px]">
                <div className="flex flex-col w-full">
                    <Controller
                        name="method_payment"
                        control={control}
                        render={({ field }) => (
                            <div className="d-flex flex-column">
                                <p className="text-[14px] mb-[4px] font-bold text-[#030303]">
                                    支払い方法
                                </p>
                                <Radio.Group defaultValue={isCard} onChange={(e) => {
                                    setIsCard(e.target.value)
                                }}>
                                    <Radio value={true}>クレジットカードで事前決済
                                        こちらのお支払いは、triplaによる手配・ク レジットカードによる事前決済となります。</Radio>
                                    <br />
                                    <Radio value={false}>現地決済</Radio>
                                </Radio.Group>
                            </div>
                        )}
                    />
                </div>

                <div className="flex flex-col w-full">
                    <Controller
                        name="credit_card"
                        control={control}
                        render={({ field }) => (
                            <div className="d-flex flex-column">
                                <p className="text-[14px] mb-[4px] font-bold text-[#000000]">
                                    クレジットカード
                                </p>
                                <Radio.Group defaultValue={1} disabled={!isCard} >
                                    <Radio value={1}>新しいクレジットカードを入力</Radio>
                                </Radio.Group>
                            </div>
                        )}
                    />
                </div>
                {/* <PaymentElement /> */}
                {isCard && <div className="d-flex flex-column">
                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                        <span className="text-red mr-1">*</span>
                        カード番号
                    </p>
                    <StyleCard options={{
                        style: {
                            base: inputStyle,
                        },
                    }} />
                </div>}

                {/* <div className="flex flex-col w-full">
                                <Controller
                                    name="card_number"
                                    control={control}
                                    rules={{ required: isCard }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">*</span>
                                                カード番号
                                            </p>
                                            {isCard ? <InputMask
                                                mask='9999 9999 9999 9999'
                                                autoComplete="off"
                                                type="text"
                                                className="textBox mr-5"
                                                {...register(
                                                    'card_number',
                                                    paymentValidation().cardNumber())}
                                                placeholder='1234 1234 1234 1234'
                                                onBlur={handleValidateWhitespace}
                                            /> :
                                                <InputMask
                                                    mask='9999 9999 9999 9999'
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox mr-5"
                                                    placeholder='1234 1234 1234 1234'
                                                    disabled
                                                />}

                                            {isCard && errors.card_number && (
                                                <span className="error">
                                                    {errors.card_number.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div> */}

                {isCard && <div className="flex flex-col w-full">
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: isCard }}
                        render={({ field }) => (
                            <div className="d-flex flex-column">
                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                    <span className="text-red mr-1">*</span>
                                    カード名義
                                </p>
                                {isCard ? <input
                                    autoComplete="off"
                                    type="text"
                                    className="textBox"
                                    {...register(
                                        'name',
                                        paymentValidation().holderName()
                                    )}
                                    onBlur={handleValidateWhitespace}
                                ></input>
                                    : <input
                                        autoComplete="off"
                                        type="text"
                                        className="textBox"
                                        disabled
                                    ></input>}

                            </div>
                        )}
                    />
                </div>}

                {isCard && <div className="d-flex flex-column">
                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                        <span className="text-red mr-1">*</span>
                        有効期限
                    </p>
                    <StyleExpiry options={{
                        style: {
                            base: inputStyle,
                        },
                    }} />
                </div>}
                {/* <div className="flex flex-col w-full">
                                <Controller
                                    name="date_expire"
                                    control={control}
                                    rules={{ required: isCard }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">*</span>
                                                有効期限
                                            </p>
                                            {isCard ? <InputMask
                                                mask='99/99'
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'date_expire',
                                                    paymentValidation().dateExpired()
                                                )}
                                                placeholder='月/年'
                                                onBlur={handleValidateWhitespace}
                                            />
                                                : <InputMask
                                                    mask='99/99'
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox"
                                                    placeholder='月/年'
                                                    disabled
                                                />}
                                            {isCard && errors.date_expire && (
                                                <span className="error">
                                                    {errors.date_expire.message?.toString()}
                                                </span>
                                            )}
                                            {errorDate && <span className="error">
                                                {errorDate}
                                            </span>}
                                        </div>
                                    )}
                                />
                            </div> */}
                {isCard && <div className="d-flex flex-column">
                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                        <span className="text-red mr-1">*</span>
                        セキュリティコード
                    </p>
                    <StyleSecurityCvc options={{
                        style: {
                            base: inputStyle,
                        },
                    }} />
                </div>}
                {/* <div className="flex flex-col w-full">
                                <Controller
                                    name="security_code"
                                    control={control}
                                    rules={{ required: isCard }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">*</span>
                                                セキュリティコード
                                            </p>
                                            {isCard ? <InputMask
                                                mask='9999'
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                maskPlaceholder={null}
                                                {...register(
                                                    'security_code',
                                                    paymentValidation().securityCode()
                                                )}
                                                placeholder='セキュリティコード'
                                                onBlur={handleValidateWhitespace}
                                            /> :
                                                <InputMask
                                                    mask='999'
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox"
                                                    placeholder='セキュリティコード'
                                                    disabled
                                                />}
                                            {isCard && errors.security_code && (
                                                <span className="error">
                                                    {errors.security_code.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div> */}
                {isCard && errors.name && (
                    <span className="error">
                        {errors.name.message?.toString()}
                    </span>
                )}
                {errorMessage && <span className="error">
                    {errorMessage}
                </span>}
            </div>

            <hr />
            <div
                className="flex-row px-[15px]"
                style={{
                    marginTop: '15px',
                    justifyContent: 'center',
                }}
            >
                <button
                    className="modal-create-user-button2 flex-row"
                    style={{ width: '100%' }}
                    type="submit"
                    disabled={loading}
                >
                    {loading && <Spin />} 決済
                </button>
            </div>
        </form>
    )
}

const StyleCard = styled(CardNumberElement)({
    border: '1px solid rgb(205, 209, 213)',
    padding: '0px 8px',
    ':focus': {
        border: '1px solid #000',
    }
})

const StyleExpiry = styled(CardExpiryElement)({
    border: '1px solid rgb(205, 209, 213)',
    padding: '0px 8px'
})

const StyleSecurityCvc = styled(CardCvcElement)({
    border: '1px solid rgb(205, 209, 213)',
    padding: '0px 8px'
})

export default FormPayment