import { FC } from 'react';
import { InvoiceDetailStyles } from './styled';
import ImageCarousel from 'components/ImageCarousel';
import { QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import styled from 'styled-components';
import useSPInvoiceDetail, { Props, ReceivedProps } from './hook';
import CalendarIcon from 'assets/icons/calendar-icon.png';
import querystring from 'query-string'

const TextAreaCustom = styled(TextArea)`
    border: none !important;
    :hover {
        border: none !important;
    }
`;

const SPInvoiceLayout: FC<Props> = ({
    invoice
}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const param = useParams()
    const queryObj = querystring.parse(location.search)

    return (
        <InvoiceDetailStyles>
            <div className="rounded-2xl py-2 px-1 text-[14px]" style={{ gap: '10px', margin: '14px 16px' }}>
                <div>
                    <p className='font-bold' >基本情報</p>
                    <div className='flex flex-row justify-between' >
                        <p className='text-[#666666]' >会社名: <span className='text-[#000]' > {invoice?.company_name} </span></p>
                        <div
                            className="text-overflow1"
                            style={{
                                whiteSpace:
                                    'nowrap',
                                color: '#727272',
                                backgroundColor: '#1CB4031A',
                                borderRadius:
                                    '15px',
                                padding:
                                    '4px 9px',
                            }}
                        >
                            <p style={{ margin: '0px', lineHeight: '30px' }}>{invoice?.status === 'unpaid' ? '決済待ち' : '決済済み'} </p>
                        </div>
                    </div>
                    <div className='flex flex-row' >
                        <img src={CalendarIcon} alt="calendar" style={{ height: '16px' }} /> <p className='text-[#666666] ml-2' >{moment(invoice?.invoice_date).format('YYYY年MM月DD日')}</p>
                    </div>
                    {/* <p className='text-[#666666]' >電話番号: <span className='text-[#000]' >{invoice?.company_bank_account}</span></p> */}
                    <p>{invoice?.company_address}</p>
                    <hr />
                    <p className='text-[#666666]'>お客様: <span className='text-[#000]' >{invoice?.customer_name}</span></p>
                    <p>{invoice?.customer_address}</p>
                </div>

                {invoice?.invoice_details && invoice?.invoice_details.map((item, index) => (
                    <div className="rounded-2xl py-2 px-3 mb-[15px] wrapperBox text-[14px]">
                        <p className='text-[16px] font-bold text-center' >{item?.content}</p>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>数量</p>
                            <p>{item?.quantity}</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>単位</p>
                            <p>{item?.unit}</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>単価</p>
                            <p>¥{item?.price && item?.price.toLocaleString('en-US')}</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>税率</p>
                            <p>{item?.tax}%</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>金額</p>
                            <p>¥{item?.total_amount_after_tax && item?.total_amount_after_tax.toLocaleString('en-US')}</p>
                        </div>
                    </div>
                ))}

            </div>
            <hr />
            <div style={{ gap: '10px', margin: '14px 16px', background: '#F9F9F9' }} className='px-[16px] pt-[10px]'>
                <div className='flex flex-row justify-between' >
                    <p className='text-[#666666]'>小計</p>
                    <p>¥{invoice?.total_before_tax.toLocaleString('en-US')}</p>
                </div>
                <div className='flex flex-row justify-between' >
                    <p className='text-[#666666]'>割引</p>
                    {/* <p>-¥{invoice?.total_tax.toLocaleString('en-US')}</p> */}
                    <p>-¥0</p>
                </div>
                <div className='flex flex-row justify-between' >
                    <p className='text-[#666666]'>消費税</p>
                    <p>¥{invoice?.total_tax.toLocaleString('en-US')}</p>
                </div>
                <div className='flex flex-row justify-between text-[20px]' >
                    <p>合計</p>
                    <p>¥{invoice?.total_after_tax.toLocaleString('en-US')}</p>
                </div>
            </div>
            <div className='bg-[#fff] flex flex-row justify-center w-full' style={{ padding: '16px 24px 16px 24px' }} >
                <div
                    className="d-flex flex-row justify-center text-lg w-full"
                    style={{
                        marginTop: '14px',
                    }}
                >
                    <button
                        className="modal-create-user-button1 d-flex flex-row"
                        style={{ width: '100%', border: '1px solid #D9D9D9', color: '#000000D9' }}
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        キャンセル
                    </button>
                </div>
            </div>
        </InvoiceDetailStyles>
    );
};

const SPInvoiceDetail: FC<ReceivedProps> = (props) => {
    return <SPInvoiceLayout {...useSPInvoiceDetail(props)} />;
};

export default SPInvoiceDetail;
