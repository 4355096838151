const quoteValidation = () => ({
    notes: () => ({
        required: '依頼内容を入力してください。',
    }),
    dateTime: () => ({
        required: '希望日時を入力してください。',
    }),
    zipCode : () =>({
        required: '現場：郵便番号を入力してください。',

    }),
    title: () => ({
        required: 'タイトルを入力してください。',
    }),
    content: () => ({
        required: '依頼内容を入力してください。',
    }),
    address: () => ({
        required: '住所を入力してください。',
    }),
    type: () => ({
        required: 'サービスを選択してください。',
    }),
});

export default quoteValidation;
