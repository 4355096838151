import { FC, useState } from 'react';
import useQuoteHistory, { Props, ReceivedProps } from './hook';
import { QuoteHistoryStyles } from './styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { QuoteStatusEnum } from 'utils/enums';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Drawer, Form, Input, Space, Tag } from 'antd';
import styled from 'styled-components';
import FilterSearch from 'assets/menuIcons/filterSearch';
import { CloseOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import './styles.scss';
import querystring from 'query-string'

const TagCustom = styled(Tag)`
    border-radius: 10px !important;
    height: 42px !important;
    padding: 0px 10px 0px 10px !important;
    display: flex !important;
    align-items: center !important;
`;

const CustomSearch = styled.div`
    .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        border-radius: 0px 10px 10px 0px !important;
    }
`;

const DrawerCustom = styled(Drawer)`
    .ant-drawer-header {
        border-bottom: 1px solid #d9d9d9;
        margin-left: 24px;
        margin-right: 24px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .ant-drawer-content {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
    .ant-drawer-header-title {
        display: grid !important;
    }
`;

const arrStatus = [
    {
        label: '新規相談',
        value: 'new_request',
    },
    { label: '対応中', value: 'in_progress' },
    { label: '対応済み', value: 'handled' },
    { label: '完了', value: 'completed' },
];

const RoundSearch = styled(Input.Search)`
    .ant-input {
        border-radius: 10px;
    }
    .ant-input:focus {
        border-color: none !important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
        box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    }
`;

const SPQuoteHistoryLayout: FC<Props> = ({
    historyData,
    selectedStatusBefore,
    hasMore,
    setSelectedStatusBefore,
    setSearchText,
    setSelectedStatusAfter,
    selectedStatusAfter,
    form,
    fetchQuoteHistory,
}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const queryObj = querystring.parse(location.search)
    const conditionSelected = (value) => {
        return selectedStatusBefore?.find((element) => element === value);
    };

    const [openDrawerSearch, setOpenDrawerSearch] = useState(false);

    const showDrawerSearch = () => {
        setSearchText(form?.getFieldValue('search'));
        setOpenDrawerSearch(true);
    };

    const onCloseDrawerSearch = () => {
        // setSelectedStatusBefore([]);
        setOpenDrawerSearch(false);
    };

    return (
        <QuoteHistoryStyles>
            <div className="px-[10px] rounded-[10px] pt-[10px] flex gap-[10px]">
                <Form form={form} className="w-[100%]">
                    <CustomSearch>
                        <Form.Item name="search">
                            <RoundSearch
                                placeholder="検索"
                                className="w-[301px] !rounded-[10px] success"
                                onSearch={(data: any) => {
                                    setSearchText(data?.trim());
                                    form.setFieldsValue({
                                        search: data?.trim(),
                                    });
                                }}
                            />
                        </Form.Item>
                    </CustomSearch>
                </Form>
                <div onClick={showDrawerSearch}>
                    <FilterSearch />
                </div>
            </div>
            <DrawerCustom
                title={
                    <div className="flex">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex justify-center">
                            <p className="m-0 bg-blue-200 text-[#000000] font-bold">
                                見積状況
                            </p>
                        </div>
                        <div className="flex-1 flex justify-end">
                            <CloseOutlined onClick={onCloseDrawerSearch} />
                        </div>
                    </div>
                }
                placement={'bottom'}
                width={500}
                open={openDrawerSearch}
                closeIcon={false}
                onClose={onCloseDrawerSearch}
                style={{ display: 'block' }}
            >
                <Space size={[0, 8]} wrap>
                    {arrStatus?.map((item: any) => (
                        <TagCustom
                            className={clsx('!cursor-pointer', {
                                '!text-[#215493] !border-[#215493]':
                                    conditionSelected(item?.value),
                            })}
                            style={{
                                backgroundColor: `${conditionSelected(item?.value)
                                    ? 'rgb(33, 84, 147, 0.15)'
                                    : 'white'
                                    }`,
                            }}
                            onClick={() => {
                                const conditionSelected =
                                    selectedStatusBefore?.find(
                                        (element) => element === item?.value,
                                    );
                                if (!conditionSelected) {
                                    setSelectedStatusBefore(
                                        (selectedStatus: any) => {
                                            return [
                                                ...selectedStatus,
                                                item?.value,
                                            ];
                                        },
                                    );
                                }
                            }}
                        >
                            {conditionSelected(item?.value) && (
                                <CloseOutlined
                                    className={clsx('!text-[15px]', {
                                        '!text-[#215493]': conditionSelected(
                                            item?.value,
                                        ),
                                    })}
                                    onClick={() => {
                                        if (conditionSelected(item?.value)) {
                                            const removeSelected =
                                                selectedStatusBefore?.filter(
                                                    (element) =>
                                                        element !== item?.value,
                                                );

                                            setSelectedStatusBefore(
                                                removeSelected,
                                            );
                                        }
                                    }}
                                />
                            )}
                            <p
                                className={clsx('m-0', {
                                    'pl-[8px]': conditionSelected(item?.value),
                                })}
                            >
                                {item?.label}
                            </p>
                        </TagCustom>
                    ))}
                </Space>
                <button
                    onClick={() => {
                        onCloseDrawerSearch();
                        setSelectedStatusAfter(selectedStatusBefore);
                    }}
                    className="!bg-[#215493] w-[100%] !rounded-[10px] !text-[white] !mt-[40px] h-[40px]"
                >
                    <span>検索</span>
                </button>
            </DrawerCustom>

            <div className="mt-[14.5px] px-3">
                <InfiniteScroll
                    dataLength={historyData.length}
                    next={fetchQuoteHistory}
                    hasMore={hasMore}
                    loader={<p></p>}
                    endMessage={<p></p>}
                >
                    <ul className="p-0">
                        {historyData.length > 0 ? historyData.map((item) => {
                            return (
                                <li
                                    key={item.id}
                                    className="m-0 cursor-pointer"
                                    onClick={() =>
                                        navigate(`/sp/quote/detail/${item.id}${queryObj.top ? '?top=true' : ''}`)
                                    }
                                >
                                    <div className="flex items-center py-2 border-b-[#D9D9D9] border-b-[1px] justify-between">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="m-0 font-bold">
                                                    {item.content}
                                                </p>
                                                <p className="m-0 text-[12px] text-[#534E54]">
                                                    {moment(
                                                        item.created_at,
                                                    ).format('YYYY年MM月DD日')}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            // className="text-overflow1"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                color: QuoteStatusEnum[
                                                    item.status
                                                ]?.textColor,
                                                backgroundColor:
                                                    QuoteStatusEnum[item.status]
                                                        ?.bgColor,
                                                borderRadius: '15px',
                                                padding: '4px 9px',
                                            }}
                                        >
                                            {
                                                QuoteStatusEnum[item.status]
                                                    ?.title
                                            }
                                        </div>
                                    </div>
                                </li>
                            );
                        }) : <div className='text-center' >データがありません。</div>}
                    </ul>
                </InfiniteScroll>
            </div>
        </QuoteHistoryStyles>
    );
};

const SPQuoteHistory: FC<ReceivedProps> = (props) => {
    return <SPQuoteHistoryLayout {...useQuoteHistory(props)} />;
};

export default SPQuoteHistory;
