import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useLocation } from 'react-router-dom';
import quoteApi from 'services/quoteApi';
import quoteService from 'services/quoteService';
import { alertError, alertSuccess } from 'utils/helper/appHelper';

interface ListData {
    id: number;
    user_name: string;
    avatar_url: string;
    address: string;
    status: string;
    created_at: string;
    quote_type: string;
    quote_type_2: string;
    quote_type_1: string;
    invoice?;
}

export type ReceivedProps = Record<never, never>;
const useQuoteList = (props: ReceivedProps) => {
    const [quoteList, setQuoteList] = useState<ListData[]>([]);
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage]: any = useState();
    const [statusPrev, setStatusPrev]: any = useState(false);
    const [statusNext, setStatusNext]: any = useState(false);
    const [quoteId, setQuoteId]: any = useState();
    const [statusSearchValue, setStatusSearchValue] = useState<any>('');
    const [typeSearchValue, setTypeSearchValue] = useState<any>('');
    const [type2SearchValue, setType2SearchValue] = useState<any>('');
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [filterVal, setFilterVal] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );
    const deleteQuote = async (evt) => {
        evt.preventDefault();
        if (quoteId) {
            try {
                const res = await quoteApi.deleteQuote(user, quoteId);
                if (res) {
                    fetchQuoteList();
                    closeDelete();
                    alertSuccess(
                        `${res.data?.quote?.content || ''} を削除しました。`,
                    );
                    setQuoteId();
                }
            } catch (error) {
                console.log('error', error);
            }
        }
    };
    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    const fetchQuoteList = async () => {
        try {
            let body: any = {
                page: page,
                per_page: 20,
                status: statusSearchValue === 'all' ? '' : statusSearchValue,
                quote_type_1: typeSearchValue === 'all' ? '' : typeSearchValue,
                quote_type_2:
                    type2SearchValue === 'all' ? '' : type2SearchValue,
            };
            if (searchVal) {
                body.filter_value = searchVal;
            }
            const data = await quoteService.getQuoteList(body);
            if (data && data.quotes.length > 0) {
                const totalCount = data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / 20);
                const totalSurplus = totalCount % 20;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                setTotalPage(totalPageCustom);
                setQuoteList(data.quotes);
            } else {
                setQuoteList([]);
            }
        } catch (error) {
            //
            alertError('データがありません。');
        }
    };

    useEffect(() => {
        if (!location.search) {
            fetchQuoteList();
        }
    }, [
        location.search,
        page,
        statusSearchValue,
        typeSearchValue,
        searchVal,
        type2SearchValue,
    ]);

    return {
        ...props,
        quoteList,
        page,
        statusPrev,
        statusNext,
        setPagePrev,
        setPageNext,
        setQuoteId,
        quoteId,
        deleteQuote,
        ModalDelete,
        openDelete,
        closeDelete,
        statusSearchValue,
        setStatusSearchValue,
        typeSearchValue,
        setTypeSearchValue,
        setFilterVal,
        filterVal,
        searchVal,
        totalPage,
        setSearchVal,
        setPage,
        type2SearchValue,
        setType2SearchValue,
    };
};

export type Props = ReturnType<typeof useQuoteList>;

export default useQuoteList;
